<script setup>
    import { Head, Link } from "@inertiajs/vue3";
    import GuestLayout from "@/Layouts/GuestLayout.vue";

    import { onMounted } from "vue";
    import { initFlowbite } from "flowbite";

    // initialize components based on data attribute selectors
    onMounted(() => {
        initFlowbite();
    });

    defineProps({});
</script>

<template>
    <Head title="Inicio" />
    <GuestLayout>
        <section class="bg-white dark:bg-gray-900">
            <div
                class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12"
            >
                <div class="mr-auto place-self-center lg:col-span-7">
                    <h1
                        class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white"
                    >
                        Programa de Estímulos al Desempeño del Personal Docente
                    </h1>
                    <!--<p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">From checkout to global sales tax compliance, companies around the world use Flowbite to simplify their payment stack.</p>-->
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <Link
                            :href="route('login')"
                            class="w-full inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
                        >
                            <img
                                src="https://academicos.uson.mx/images/logo_idenTIdad.png"
                                alt="Logo IdenTIdad Unison"
                                class="w-5 h-5.5 mr-2 -ml-1"
                            />
                            Iniciar Sesión
                        </Link>
                        <a
                            href="https://dadip.unison.mx/programa-de-estimulo-al-desempeno-docente-pedpd/"
                            class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-naranja-100 hover:border-naranja-300 focus:ring-4 focus:ring-naranja-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                        >
                            Saber más
                        </a>
                    </div>
                </div>
                <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
                    <img
                        src="https://dadip.unison.mx/wp-content/themes/awaken/images/bg-opciones3.png"
                        alt="mockup"
                    />
                </div>
            </div>
        </section>
    </GuestLayout>
</template>
